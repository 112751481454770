import {  notification } from 'antd';
class AppHelpers{

 static openNotification = ({title,description}:any) => {
  const args = {
    message: title,
    description: description,
    duration: 4,
    
  };
  notification.success(args);
};

static openErrorNotification = ({title,description}:any) => {
  const args = {
    message: title,
    description: description,
    duration: 4,
    
  };
  notification.error(args);
};

    static getImageUrl(mediaObject: any) {
        if (!mediaObject.isUnsplash) {
          return `https://${mediaObject.bucket}.s3.eu-central-1.amazonaws.com/${mediaObject.key}`;
        } else {
          return mediaObject.unsplashImageUrl;
        }
      }
}

export default AppHelpers