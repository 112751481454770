import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './store/store';
import { Provider as UseHttpProvider } from 'use-http';
import { BASE_URL } from './constants/urls';
import { options } from './utils/interceptors/useHttp.interceptor';
import 'remixicon/fonts/remixicon.css'

ReactDOM.render(
  <React.StrictMode>
      <UseHttpProvider options={options} url={BASE_URL}>
      <Provider store={store}>
    <App />
    </Provider>
    </UseHttpProvider> 
  </React.StrictMode>,
  document.getElementById('root')
);
