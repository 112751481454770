import { useEffect, useState } from "react";
import useFetch from "use-http";
import { GET_CARD_URL } from "../../constants/urls";
import { ICardData, IUserCards } from "../../models/card.interface";

interface IReturnState {
  cards: Array<ICardData>;
  loading: boolean;
}
export const useCardState = (): IReturnState => {
  const { get, loading, response } = useFetch();
  const [cards, setCards] = useState<Array<ICardData>>([]);

  const fetchCards = async () => {
    const cardResponse: IUserCards
     =  await get(GET_CARD_URL);

    if (response.ok && cardResponse.status == 200) {
        setCards(cardResponse.data);
    } else {
    }
  };
  useEffect(() => {
    fetchCards()
  }
  , []);
  return { cards, loading };
};