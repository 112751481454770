import { configureStore } from "@reduxjs/toolkit";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import  { createRootReducer, RootState } from "./rootreducer";
import { rootSaga } from "./saga/rootsaga";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer:createRootReducer(),
  middleware:(getDefaulyMiddleware)=>getDefaulyMiddleware({thunk:false}).concat(sagaMiddleware)
});
sagaMiddleware.run(rootSaga)

export default store;
