import React, { useEffect } from "react";
import { Card, Col, Row, Table, Typography } from "antd";
import { useBrandState } from "../states/brand-state";
import { brandData } from "./data/brandData";
import { format } from "date-fns";

const { Title } = Typography;

function BrandListing() {
  const { loading, brands } = useBrandState();
  useEffect(() => {
    console.log(brands);
  }, []);
  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Brands"
            >
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={brandData}
                  dataSource={
                    brands &&
                    brands.map((item: any) => {
                      const createdAt = format(
                        new Date(item.createdAt),
                        "MMM-dd-yyyy HH:mm"
                      );
                      return {
                        key: item._id,
                        title: (
                          <>
                            <div>
                              <Title
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                }}
                                level={5}
                              >
                                {item.brandTitle}
                              </Title>
                            </div>
                          </>
                        ),
                        description: (
                          <>
                            <div>
                              <Title
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                  whiteSpace: "normal",
                                }}
                                level={5}
                              >
                                {item.brandDescription}
                              </Title>
                            </div>
                          </>
                        ),
                        logoUrl: (
                          <>
                            <div>
                              <Title
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                  whiteSpace: "normal",
                                }}
                                level={5}
                              >
                                {item.logoUrl}
                              </Title>
                            </div>
                          </>
                        ),
                        createdAt: (
                          <>
                            <div>
                              <Title
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                }}
                                level={5}
                              >
                                {createdAt}
                              </Title>
                            </div>
                          </>
                        ),
                      };
                    })
                  }
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default BrandListing;
