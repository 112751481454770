import { localStorageService } from "../localStorage/localStorage";

export const options: any = {
  cachePolicy: 'no-cache',
  interceptors: {
    request: async ({ options, url, path, route }: any) => {
      const token = localStorageService.getItem("token");
      options.headers.Authorization = `Bearer ${token}`;
      return options;
    },
    response: async ({ response }: any) => {
      return response;
    },
  },
};
