import { takeLatest, put, all, fork } from "redux-saga/effects";
import { LOGIN_API_URL } from "../../../constants/urls";
import AppHelpers from "../../../utils/app/appHelper";
import { localStorageService } from "../../../utils/localStorage/localStorage";
import FetchSendRequest from "../../../utils/sendRequest/sendRequest";
import  { login, loginFailed, loginRequest } from "../../slice/auth.slice";

const sendRequest = FetchSendRequest.instance;

function* postAPILoginCall(payload: any): Generator {
  try {
    const {history,...rest} = payload.payload
    const response: any = yield sendRequest.MakeAPICall({
      url: LOGIN_API_URL,
      body: rest,
    });
    if (response) {
      const apiResponse = response
      switch (apiResponse.message) {
        case "INVALID_EMAIL":
          AppHelpers.openErrorNotification({
            title:"Error",
            description:"Invalid Username or Password"
           })
          return yield put(loginFailed(apiResponse));
        case "INACTIVE_ACCOUNT":
          AppHelpers.openErrorNotification({
            title:"Error",
            description:"Your account is not active please activate it first"
           })
          return yield put(loginFailed(apiResponse));
          
        case "INVALID_PASSWORD":
          AppHelpers.openNotification({
            title:"Error",
            description:"Invalid Username or Password"
           })
          return yield put(loginFailed(apiResponse));
          case "WEAK_PASSWORD":
            AppHelpers.openErrorNotification({
              title:"Error",
              description:"Invalid Username or Password"
             })
            return yield put(loginFailed(apiResponse));
        case "LOGIN_SUCCESS":
          if(apiResponse.data.user.permission[0].permissions.includes("SUPER_ADMIN")){
            yield put(login(apiResponse));
            AppHelpers.openNotification({
              title:"Success",
              description:"You are logged in successfully"
             })
            history.push("/dashboard")
            localStorageService.setItem("token",response.data.user.token)
          }
          else{
            AppHelpers.openErrorNotification({
              title:"Error",
              description:"You are not authorized to perform this action"
             })
            return yield put(loginFailed(apiResponse));
          }
      } }
  } catch (e: any) {
    AppHelpers.openErrorNotification({
      title:"Error",
      description:e.message
     })
    yield put(loginFailed(e.message));
  }
}


export function* watchAuthApiCall(): any {
  yield all([takeLatest(loginRequest.type, postAPILoginCall)]);
}
