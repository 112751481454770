const storage = window.localStorage
export class localStorageService{
  static getItem(key:string){
    const value = storage.getItem(key)
    return value
  }


  static setItem(key:string,value:any){
    storage.setItem(key,value)
  }
}

