import { useEffect, useState } from "react";
import useFetch from "use-http";
import { GET_BRAND_URL,  } from "../../constants/urls";
import { Brand, IBrand } from "../../models/brand-interface";

interface IReturnState {
  brands: Array<Brand>;
  loading: boolean;
}
export const useBrandState = (): IReturnState => {
  const { get, loading, response } = useFetch();
  const [brands, setBrands] = useState<Array<Brand>>([]);

  const fetchBrand = async () => {
    const brandResponse: IBrand =  await get(GET_BRAND_URL);

    if (response.ok && brandResponse.status == 200) {
        setBrands(brandResponse.data);
    } else {
    }
  };
  useEffect(() => {
    fetchBrand()
  }
  , []);
  return { brands, loading };
};
