import { takeLatest, put, all } from "redux-saga/effects";
import { GET_ALL_USERS_URL } from "../../../constants/urls";
import FetchSendRequest from "../../../utils/sendRequest/sendRequest";
import { getAllUsersError, getAllUsersRequest, getAllUsersSuccess } from "../../slice/user.slice";

const sendRequest = FetchSendRequest.instance;

function* getAllUsersApiCall(): Generator {
  try {
    const response: any = yield sendRequest.MakeAPICall({
      url: GET_ALL_USERS_URL
    });
    if (response) {
      const apiResponse = response
      if(apiResponse.status == 200){
          yield put(getAllUsersSuccess(apiResponse.data))
      }
      else{
          yield put(getAllUsersError(apiResponse))
      }
    }
  } catch (e: any) {
    yield put(getAllUsersError(e.message));
  }
}


export function* watchUsersApiCall(): any {
  yield all([takeLatest(getAllUsersRequest.type, getAllUsersApiCall)]);
}
