import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
} from "antd";
import signinbg from "../assets/images/img-signin.jpg";
import {  useDispatch, useSelector } from "react-redux";
import {  useHistory } from "react-router-dom";
import { login, loginRequest } from "../store/slice/auth.slice";
const { Title } = Typography;
const { Content } = Layout;

export default function SignIn() {
  const history = useHistory()
  const {error,loading}  = useSelector((store:any) =>store.auth );
  const disaptch = useDispatch()
  const onFinish=async (formData:any)=>{
    disaptch(loginRequest({...formData,history}))
  }
  const onFinishFailed = ()=>{
    console.log('finish faliedl')
  }
  return (
    <>
        <Layout className="layout-default layout-signin">
          <br/>
          <br/>
          <Content className="signin">
            <Row gutter={[24, 0]} justify="space-around">
            <Col
                className="sign-img"
                style={{ padding: 10 }}
                xs={{ span: 24 }}
                lg={{ span: 8 }}
                md={{ span: 8 }}
              >
                <img src={signinbg} alt="" />
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 6, offset: 2 }}
                md={{ span: 12 }}
              >
                <Title className="mb-15">Sign In</Title>
                <Title className="font-regular text-muted" level={5}>
                  Enter your email and password to sign in
                </Title>
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>

                  <Form.Item
                    className="username"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>

                  <Form.Item
                    name="remember"
                    className="aligin-center"
                    valuePropName="checked"
                  >
                    <Switch defaultChecked />
                    Remember me
                  </Form.Item>

                  <Form.Item>
                    <Button
                    loading={loading}
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      SIGN IN
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Content>
        </Layout>
      </>
  )
}
