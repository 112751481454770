import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Avatar,
  Typography,
  Button,
  Modal,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsersRequest,
  setFilterdData,
} from "../../store/slice/user.slice";
import AppHelpers from "../../utils/app/appHelper";
import { useUsersState } from "../states/user-states";
import { columns } from "./data/columns";
const { Title, Text } = Typography;

function UsersListing() {
  const { loading, filteredData } = useSelector((store: any) => store.user);
  const { onBlock, loading: blockUserLoading, onUnblock } = useUsersState();
  const dispatch = useDispatch();
  const getAllUsers = () => {
    dispatch(getAllUsersRequest());
  };
  useEffect(() => {
    getAllUsers();
  }, []);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalUnblockVisible, setIsModalUnblockVisible] = useState(false);

  const showBlockModal = () => {
    setIsModalVisible(true);
  };

  const handleBlockCancel = () => {
    setIsModalVisible(false);
  };
  const showUnblockModal = () => {
    setIsModalUnblockVisible(true);
  };

  const handleUnblockCancel = () => {
    setIsModalUnblockVisible(false);
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Users Table"
              extra={
                <>
                  <Radio.Group
                    onChange={(e) => {
                      dispatch(setFilterdData(e.target.value));
                    }}
                    defaultValue="all"
                  >
                    <Radio.Button value="all">All</Radio.Button>
                    <Radio.Button value="active">Active</Radio.Button>
                    <Radio.Button value="inactive">InActive</Radio.Button>
                  </Radio.Group>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={filteredData.map((item: any) => {
                    return {
                      key: item._id,
                      name: (
                        <>
                          <Avatar.Group>
                            <Avatar
                              className="shape-avatar"
                              shape="square"
                              size={40}
                              src={AppHelpers.getImageUrl(item.profileImage)}
                            ></Avatar>
                            <div className="avatar-info">
                              <Title level={5}>
                                {item.firstName} {item.lastName}
                              </Title>
                              <p>{item.email}</p>
                            </div>
                          </Avatar.Group>{" "}
                        </>
                      ),
                      function: (
                        <>
                          <div className="author-info">
                            <Title level={5} style={{ fontWeight: "normal" }}>
                              {item?.permission[0]?.permissions[0] ===
                              "SUPER_ADMIN"
                                ? "Admin"
                                : item?.permission[0]?.permissions[0] ===
                                  "BASIC_USER"
                                ? "Customer"
                                : "Publisher"}
                            </Title>
                          </div>
                        </>
                      ),
                      status: (
                        <>
                          <Text>
                            {item.accountStatus == "ACTIVE"
                              ? "Active"
                              : "Inactive"}
                          </Text>
                        </>
                      ),
                      employed: (
                        <>
                          <div className="ant-employed">
                            <span>
                              {new Date(item.createdAt).toDateString()}
                            </span>
                          </div>
                        </>
                      ),
                      action: (
                        <>
                          {item.accountStatus == "BLOCKED" ? (
                            <>
                              <div onClick={showUnblockModal}>
                                <Button
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                  }}
                                  size="small"
                                >
                                  UNBLOCK
                                </Button>
                              </div>
                              <Modal
                                title="Basic Modal"
                                visible={isModalUnblockVisible}
                                onCancel={handleUnblockCancel}
                                onOk={() => onUnblock(item.userId)}
                              >
                                <p>
                                  Are you sure you want to unblock this user?
                                </p>
                              </Modal>
                            </>
                          ) : (
                            <>
                              <div onClick={showBlockModal}>
                                <Button
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                  }}
                                  size="small"
                                >
                                  BLOCK
                                </Button>
                              </div>
                              <Modal
                                title="Basic Modal"
                                visible={isModalVisible}
                                onCancel={handleBlockCancel}
                                onOk={() => onBlock(item.userId)}
                              >
                                <p>Are you sure you want to block this user?</p>
                              </Modal>
                            </>
                          )}
                          {/* {blockUserLoading && <>wait..</>} */}
                          {/* {!blockUserLoading && (
                            <Button
                              style={{ backgroundColor: "red", color: "white" }}
                              size="small"
                              onClick={() => onBlock(item.userId)}
                            >
                              BLOCK
                            </Button>
                          )} */}
                        </>
                      ),
                    };
                  })}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UsersListing;