import { takeLatest, put, all, } from "redux-saga/effects";
import { DELETE_REPORTED_CARD, GET_REPORTED_CARDS_URL, RESOLVE_REPORTED_CARD } from "../../../constants/urls";
import FetchSendRequest from "../../../utils/sendRequest/sendRequest";
import { deleteReportedCardError, deleteReportedCardRequest, deleteReportedCardSuccess, getReportedCardsError, getReportedCardsRequest, getReportedCardsSuccess, resolveReportedCardError, resolveReportedCardRequest, resolveReportedCardSuccess } from "../../slice/cards.slice";

const sendRequest = FetchSendRequest.instance;

function* getReportedCardsApiCall(payload: any): Generator {
  try {
    const response: any = yield sendRequest.MakeAPICall({
      url: GET_REPORTED_CARDS_URL
    });
    if (response) {
      const apiResponse = response
      yield put(getReportedCardsSuccess(apiResponse.data))
    }
  } catch (e: any) {
    yield put(getReportedCardsError(e.message));
  }
}

function* resolveReportedCardApiCall(payload: any): Generator {
  const {payload:id}=payload
  try {
    const response: any = yield sendRequest.MakeAPICall({
      url: `${RESOLVE_REPORTED_CARD}/${id}`,
      method:"PATCH"
    });
    if (response) {
      const apiResponse = response
      yield put(resolveReportedCardSuccess(id))
    }
  } catch (e: any) {
    yield put(resolveReportedCardError(id));
  }
}


function* deleteReportedCardsApiCall(payload: any): Generator {
  const {payload:id}=payload
  try {
    console.log(payload)
    const response: any = yield sendRequest.MakeAPICall({
      url:  `${DELETE_REPORTED_CARD}/${id}`,
        method:"DELETE"
    });
    if (response) {
      const apiResponse = response
      yield put(deleteReportedCardSuccess(id))
    }
  } catch (e: any) {
    yield put(deleteReportedCardError(id));
  }
}


export function* watchCardsApiCall(): any {
  yield all([takeLatest(getReportedCardsRequest.type, getReportedCardsApiCall),
  takeLatest(deleteReportedCardRequest,deleteReportedCardsApiCall),
  takeLatest(resolveReportedCardRequest,resolveReportedCardApiCall),
  ]);
}
