import React, { useEffect } from "react";
import { Avatar, Button, Card, Col, Row, Table, Typography } from "antd";
import { cardData } from "./data/cardData";
import { useCardState } from "../states/card-state";
import AppHelpers from "../../utils/app/appHelper";
import { ICardData } from "../../models/card.interface";
import { format } from "date-fns";

const { Title } = Typography;
function CardListing() {
  const { cards, loading } = useCardState();

  useEffect(() => {
    console.log(cards);
  }, []);

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Cards"
            >
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={cardData}
                  dataSource={
                    cards &&
                    cards.map((item: ICardData) => {
                      const createdAt = format(
                        new Date(item.createdAt),
                        "MMM-dd-yyyy HH:mm"
                      );
                      return {
                        key: item.identity.cardId,
                        image: (
                          <>
                            <Avatar.Group>
                              <Avatar
                                className="shape-avatar"
                                shape="square"
                                size={100}
                                src={AppHelpers.getImageUrl(item.heroMedia[0])}
                              ></Avatar>
                            </Avatar.Group>{" "}
                          </>
                        ),
                        title: (
                          <>
                            <div>
                              <Title
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                  whiteSpace: "normal",
                                }}
                                level={5}
                              >
                                {item.cardDetails.title}
                              </Title>
                            </div>
                          </>
                        ),
                        description: (
                          <>
                            <div>
                              <Title
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                  whiteSpace: "normal",
                                }}
                                level={5}
                              >
                                {item.cardDetails.description}
                              </Title>
                            </div>
                          </>
                        ),
                        createdat: (
                          <>
                            <div>
                              <Title
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                  whiteSpace: "normal",
                                }}
                                level={5}
                              >
                                {createdAt}
                              </Title>
                            </div>
                          </>
                        ),
                        publisher: (
                          <>
                            <div>
                              <Title
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                  whiteSpace: "normal",
                                }}
                                level={5}
                              >
                                {item.publisher.firstName}
                                <br />
                                {item.publisher.lastName}
                              </Title>
                            </div>
                          </>
                        ),
                        expired: (
                          <>
                            <div>
                              <Title
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                  whiteSpace: "normal",
                                }}
                                level={5}
                              >
                                {item.campaign?.expiredAt}
                              </Title>
                            </div>
                          </>
                        ),
                        action: (
                          <>
                            <div>
                              <Button
                                className="main-color"
                                style={{
                                  color: "white",
                                }}
                                size="small"
                              >
                                VISIBLE
                              </Button>
                            </div>
                          </>
                        ),
                      };
                    })
                  }
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default CardListing;
