// table code start
export const campaignData = [
    {
      title: "CAMPAIGN IMAGE",
      dataIndex: "image",
      key: "image",
      width: "14%",
    },
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
    },
  
    {
      title: "DESCRIPTION",
      key: "description",
      dataIndex: "description",
    },
    {
      title: "CREATOR",
      key: "creator",
      dataIndex: "creator",
    },
    {
      title: "TIME",
      key: "time",
      dataIndex: "time"
    },
    {
      title: "ACTION",
      key: "action",
      dataIndex: "action"
    }
  ];
  