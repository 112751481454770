import { createSlice } from '@reduxjs/toolkit';
import { CardsState } from '../state-types/cardsState';

  
const initialState:CardsState = {
  loading:false,
  data:[],
  filteredData:[],
  filter:"all",
  error:{}

};
  
const CardsSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    getReportedCardsRequest:(state)=>{
        state.loading=true
    },
    getReportedCardsSuccess: (state, { payload }) => {
    state.loading = false;
    state.data = payload;
    state.filteredData=payload
    },
    getReportedCardsError:(state,{payload}) =>{
      state.loading = false
      state.error = payload
    },
    resolveReportedCardRequest:(state,{payload})=>{
      const index = state.filteredData.findIndex((item:any)=>item.reportId == payload)
      const item = state.filteredData[index]
      item['loading'] = true
      state.filteredData.splice(index,1,item)
    },
    resolveReportedCardSuccess:(state,{payload})=>{
      const index = state.filteredData.findIndex((item:any)=>item.reportId == payload)
      const item = state.filteredData[index]
      item['loading'] = false
      item['resolved'] = true
      state.filteredData.splice(index,1,item)
    },
    resolveReportedCardError:(state,{payload})=>{
      const index = state.filteredData.findIndex((item:any)=>item.reportId == payload)
      const item = state.filteredData[index]
      item['loading'] = false
      state.filteredData.splice(index,1,item)
    },
    deleteReportedCardRequest:(state,{payload})=>{
      const index = state.filteredData.findIndex((item:any)=>item.reportId == payload)
      const item = state.filteredData[index]
      item['loading'] = true
      state.filteredData.splice(index,1,item)
    }
    ,
    deleteReportedCardSuccess:(state,{payload})=>{
      const index = state.filteredData.findIndex((item:any)=>item.reportId == payload)
      const item = state.filteredData[index]
      state.filteredData.splice(index,1)
    },
    deleteReportedCardError:(state,{payload})=>{
      const index = state.filteredData.findIndex((item:any)=>item.reportId == payload)
      const item = state.filteredData[index]
      state.filteredData.splice(index,1)
    },
    setCardFilters:(state,{payload})=>{
      if(payload=="resolved"){
        state.filteredData =  state.data.filter((item:any)=>item.resolved)
       }
       else if(payload == 'unresolved'){
        state.filteredData = state.data.filter((item:any)=>!item.resolved)
       }
       else{
        state.filteredData = state.data
       }
    }
  },
});
  
export const { getReportedCardsRequest,getReportedCardsSuccess,getReportedCardsError,
    resolveReportedCardRequest,resolveReportedCardSuccess,resolveReportedCardError,
    deleteReportedCardError,deleteReportedCardSuccess,deleteReportedCardRequest,
    setCardFilters
  } = CardsSlice.actions;
              
export default CardsSlice.reducer;