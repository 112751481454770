import { useState, useEffect } from "react";

import {
  Row,
  Col,
  Breadcrumb,
  Badge,
  Dropdown,
  Button,
  List,
  Avatar,
  Input,
  Typography,
} from "antd";
import { NavLink, Link, useHistory } from "react-router-dom";
import avtar from "../../assets/images/team-2.jpg";
import { headerPropTypes } from "../../utils/propTypes/headerPropTypes";
import { useSelector } from "react-redux";

const data = [
  {
    title: "New message from Sophie",
    description: (
      <>
        <i
          className="ri-time-line"
          style={{ fontSize: "12px", fontWeight: "normal", color: "black" }}
        ></i>{" "}
        2 days ago
      </>
    ),
    avatar: avtar,
  },
  {
    title: "New album by Travis Scott",
    description: (
      <>
        <i
          className="ri-time-line"
          style={{ fontSize: "12px", fontWeight: "normal", color: "black" }}
        ></i>{" "}
        2 days ago
      </>
    ),

    avatar: (
      <Avatar shape="square">
        <i
          className="ri-wifi-line"
          style={{ fontSize: "20px", fontWeight: "normal", color: "black" }}
        ></i>
      </Avatar>
    ),
  },
  {
    title: "Payment completed",
    description: (
      <>
        <i
          className="ri-time-line"
          style={{ fontSize: "12px", fontWeight: "normal", color: "black" }}
        ></i>{" "}
        2 days ago
      </>
    ),
    avatar: (
      <Avatar shape="square">
        <i
          className="ri-bank-card-fill"
          style={{ fontSize: "20px", fontWeight: "normal", color: "black" }}
        ></i>
      </Avatar>
    ),
  },
];

const menu = (
  <List
    min-width="100%"
    className="header-notifications-dropdown "
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item) => (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar shape="square" src={item.avatar} />}
          title={item.title}
          description={item.description}
        />
      </List.Item>
    )}
  />
);

function Header({ name, subName, onPress }: headerPropTypes) {
  const { Title, Text } = Typography;
  const { isLogedIn } = useSelector((store: any) => store.auth);
  const [visible, setVisible] = useState(false);
  const [sidenavType, setSidenavType] = useState("transparent");
  const history = useHistory();
  useEffect(() => window.scrollTo(0, 0));
  const logOut = () => {
    localStorage.removeItem("token");
    history.push("/sign-in");
  };
  const showDrawer = () => setVisible(true);
  const hideDrawer = () => setVisible(false);

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Pages</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{name.replace("/", "")}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="ant-page-header-heading">
            <span
              className="ant-page-header-heading-title"
              style={{ textTransform: "capitalize" }}
            >
              {subName.replace("/", "")}
            </span>
          </div>
        </Col>
        <Col span={24} md={18} className="header-control">
          <Badge size="small" count={4}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <a
                href="#pablo"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <i
                  className="ri-notification-3-fill"
                  style={{ fontSize: "18px", fontWeight: "normal" }}
                ></i>
              </a>
            </Dropdown>
          </Badge>
          <Button type="link" onClick={showDrawer}>
            <i
              className="ri-settings-5-fill"
              style={{ fontSize: "18px", fontWeight: "normal", color: "black" }}
            ></i>
          </Button>
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            <i
              className="ri-toggle-fill"
              style={{ fontSize: "18px", fontWeight: "normal", color: "black" }}
            ></i>
          </Button>
          {isLogedIn ? (
            <a onClick={() => logOut()}>
              <span>Logout</span>{" "}
            </a>
          ) : (
            <Link to="/sign-in" className="btn-sign-in">
              <i
                className="ri-profile-line"
                style={{ fontSize: "18px", fontWeight: "normal" }}
              ></i>
              <span>Sign in</span>
            </Link>
          )}
          <Input
            className="header-search"
            placeholder="Type here..."
            prefix={
              <i
                className="ri-search-line"
                style={{ fontSize: "16px", fontWeight: "normal" }}
              ></i>
            }
          />
        </Col>
      </Row>
    </>
  );
}

export default Header;
