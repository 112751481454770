import { createSlice } from '@reduxjs/toolkit';
import { UserState } from '../state-types/userState';


  
const initialState:UserState = {
  loading:false,
  data:[],
  error:{},
  filteredData:[],
  filter:"all"
};
  
const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getAllUsersRequest:(state)=>{
        state.loading=true
    },
    getAllUsersSuccess: (state, { payload }) => {
    state.loading = false;
    state.data = payload;
    state.filteredData=payload
    },
    getAllUsersError:(state,{payload}) =>{
      state.loading = false
      state.error = payload
    },
    setFilterdData:(state,{payload})=>{
      console.log(payload)
      if(payload=="active"){
        state.filteredData =  state.data.filter((item:any)=>item.accountStatus == "ACTIVE")
       }
       else if(payload == 'inactive'){
         console.log(state.data)
        state.filteredData = state.data.filter((item:any)=>item.accountStatus == "NONACTIVE")
       }
       else{
        state.filteredData = state.data
       }
    }
  },
});
  
export const { getAllUsersRequest,getAllUsersSuccess,getAllUsersError,setFilterdData } = UserSlice.actions;
              
export default UserSlice.reducer;