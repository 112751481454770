import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { SidenavPropTypes } from "../../utils/propTypes/headerPropTypes";

function Sidenav({ color }: SidenavPropTypes) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>Snapi</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              <i
                className="ri-dashboard-line"
                style={{ fontSize: "16px", fontWeight: "normal" }}
              ></i>
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/users">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              <i
                className="ri-user-line"
                style={{ fontSize: "16px", fontWeight: "normal" }}
              ></i>
            </span>
            <span className="label">Users</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="9">
          <NavLink to="/reported-cards">
            <span
              className="icon"
              style={{
                background: page === "reported-cards" ? color : "",
              }}
            >
              <i
                className="ri-alarm-warning-line"
                style={{ fontSize: "16px", fontWeight: "normal" }}
              ></i>
            </span>
            <span className="label">Reported Cards</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="10">
          <NavLink to="/campaign">
            <span className="icon">
              <i
                className="ri-volume-up-line"
                style={{ fontSize: "16px", fontWeight: "normal" }}
              ></i>
            </span>
            <span className="label">Campaigns</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="11">
          <NavLink to="/card">
            <span className="icon">
              <i
                className="ri-menu-line"
                style={{ fontSize: "16px", fontWeight: "normal" }}
              ></i>
            </span>
            <span className="label">Cards</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="12">
          <NavLink to="/brand">
            <span className="icon">
              <i
                className="ri-store-line"
                style={{ fontSize: "16px", fontWeight: "normal" }}
              ></i>
            </span>
            <span className="label">Brands</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="12">
          <NavLink to="/packages">
            <span className="icon">
              <i
                className="ri-store-line"
                style={{ fontSize: "16px", fontWeight: "normal" }}
              ></i>
            </span>
            <span className="label">Snapi Packages</span>
          </NavLink>
        </Menu.Item>

        {/* <Menu.Item key="3">
          <NavLink to="/billing">
            <span
              className="icon"
              style={{
                background: page === "billing" ? color : "",
              }}
            >
             <i className="ri-bill-line" style={{ fontSize: "16px", fontWeight: "normal"}}></i>
            </span>
            <span className="label">Billing</span>
          </NavLink>
        </Menu.Item> */}
        {/* <Menu.Item key="4">
          <NavLink to="/rtl">
            <span
              className="icon"
              style={{
                background: page === "rtl" ? color : "",
              }}
            >
              <i className="ri-layout-right-line" style={{ fontSize: "16px", fontWeight: "normal"}}></i>
            </span>
            <span className="label">RTL</span>
          </NavLink>
        </Menu.Item> */}
        {/* <Menu.Item className="menu-item-header" key="5">
          Account Pages
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/profile">
            <span
              className="icon"
              style={{
                background: page === "profile" ? color : "",
              }}
            >
             <i className="ri-profile-line" style={{ fontSize: "16px", fontWeight: "normal"}}></i>
            </span>
            <span className="label">Profile</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="7">
          <NavLink to="/sign-in">
            <span className="icon"><i className="ri-login-box-line" style={{ fontSize: "16px", fontWeight: "normal"}}></i></span>
            <span className="label">Sign In</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="8">
          <NavLink to="/sign-up">
            <span className="icon"><i className="ri-arrow-up-circle-line" style={{ fontSize: "16px", fontWeight: "normal"}}></i></span>
            <span className="label">Sign Up</span>
          </NavLink>
        </Menu.Item> */}
      </Menu>
      <div className="aside-footer"></div>
    </>
  );
}

export default Sidenav;
