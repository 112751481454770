import { useDispatch } from "react-redux";
import useFetch from "use-http";
import { BLOCK_USER_URL } from "../../constants/urls";
import { getAllUsersRequest } from "../../store/slice/user.slice";

interface IReturnState {
  onBlock: (userId: string) => void;
  onUnblock: (userId: string) => void;
  loading: boolean;
}
export const useUsersState = (): IReturnState => {
  const { post, loading, response } = useFetch();
  const dispatch = useDispatch();

  const onBlock = async (userId: string) => {
    await post(BLOCK_USER_URL, { userId, accountStatus: "BLOCKED" });

    if (response.ok) {
      dispatch(getAllUsersRequest());
    } else {
    }
  };

  const onUnblock = async (userId: string) => {
    await post(BLOCK_USER_URL, { userId, accountStatus: "ACTIVE" });

    if (response.ok) {
      dispatch(getAllUsersRequest());
    } else {
    }
  };

  return { onBlock, onUnblock, loading };
};
