import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useFetch from "use-http";
import { GET_PACKAGES_URL, UPDATE_PACKAGES_URL } from "../../constants/urls";
import { PackageActions } from "../../interfaces/package.enum";
import { IBaseServerResponse } from "../../models/base-response.interface";
import {
  IPackage,
  IPackageList,
  ISinglePackage,
} from "../../models/packages.interface";

interface IReturnState {
  packages: Array<ISinglePackage>;
  loading: boolean;
  updatePackageActions: (
    id: string,
    title: string,
    description: string,
    actions: Array<string>
  ) => void;
}

export const usePackagesState = (): IReturnState => {
  const { get, put, loading, response } = useFetch();
  const [packages, setPackages] = useState<Array<ISinglePackage>>([]);
  const history = useHistory();

  const fetchPackages = async () => {
    const packagesResponse: IPackage = await get(GET_PACKAGES_URL);

    if (response.ok && packagesResponse.status == 200) {
      setPackages(packagesResponse.data);
    } else {
    }
  };

  const updatePackageActions = async (
    id: string,
    title: string,
    description: string,
    actions: Array<string>
  ) => {
    const updatePackage: IBaseServerResponse = await put(
      `${UPDATE_PACKAGES_URL}/${id}`,
      {
        packageTitle: title,
        packageDescription: description,
        actionsAllowed: actions,
      }
    );

    if (updatePackage.message == "SUCCESS") {
      alert("Package Updated");
      history.goBack();
    } else {
      alert("something went wrong");
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  return { packages, loading, updatePackageActions };
};
