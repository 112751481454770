// table code start
export const columns = [
    {
      title: "Card",
      dataIndex: "name",
      key: "reportedCard.cardDetails.title",
      width: "10%",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "function",
      width: "10%",
    },
  
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      width: "20%",
    },
    {
      title: "Reported Date",
      key: "reported_date",
      dataIndex: "createdAt",
      width: "10%",
    },
    {
      title: "Resolved",
      key: "status",
      dataIndex: "status",
      width: "10%",
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "actions",
      width: "10%",
    },
  ];