import { all, fork } from "redux-saga/effects";
import {watchAuthApiCall} from "./Auth/auth.saga";
import { watchCardsApiCall } from "./cards/card.saga";
import { watchUsersApiCall } from "./users/users.saga";

export function* rootSaga(): Generator {
    yield all([
      fork(
      watchAuthApiCall,
    ),
    fork(
      watchUsersApiCall,
    ),
    fork(
      watchCardsApiCall,
    )
    ]);
  }