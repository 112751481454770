export const BASE_URL = "https://p880gc8okwkwwwccgsock04s.209.74.71.128.sslip.io/api";
//export const BASE_URL = "http://localhost:3030/api";

export const LOGIN_API_URL = `${BASE_URL}/auth/login`;
export const GET_ALL_USERS_URL = `${BASE_URL}/admin/users`;
export const GET_REPORTED_CARDS_URL = `${BASE_URL}/admin/report-cards`;
export const RESOLVE_REPORTED_CARD = `${BASE_URL}/admin/report-card/resolve`;
export const DELETE_REPORTED_CARD = `${BASE_URL}/admin/report-card/delete`;
export const BLOCK_USER_URL = "admin/users/account-status";
export const GET_CAMPAIGN_URL = "/admin/campaigns";
export const GET_CARD_URL = "/admin/cards";
export const GET_BRAND_URL = "admin/brands";
export const GET_PACKAGES_URL = "package";
export const UPDATE_PACKAGES_URL = GET_PACKAGES_URL;
