import { localStorageService } from "../localStorage/localStorage";


export interface RequestApi {
    url: string;
    method?: string;
    isLoader?: boolean;
    body?: any;
  }

class FetchSendRequest {
  private static _instance: FetchSendRequest = new FetchSendRequest();
  // private appDetail: ApplicationDetail = ApplicationDetail.instance;
  countStartApi = 0;
  countEndApi = 0;

  constructor() {
    if (FetchSendRequest._instance) {
      throw new Error("Use DataService.instance");
    }
    FetchSendRequest._instance = this;
  }

  static get instance(): FetchSendRequest {
    return FetchSendRequest._instance;
  }

  async MakeAPICall(requestJSON: RequestApi) {
    const token = localStorageService.getItem("token");
    let requestBody = {};

    if (requestJSON && requestJSON.isLoader !== false) {
      this.countStartApi += 1;
      // store.dispatch({ type: 'REQUEST', status: 'REQUESTED' });
    }

    if (requestJSON && requestJSON.body) {
      requestBody = requestJSON.body;
    }

    const promise = await fetch(requestJSON.url, {
      method: requestJSON.method
        ? requestJSON.method
        : requestJSON.body
        ? "POST"
        : "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: requestJSON.body ? JSON.stringify(requestBody) : null,
    }).then((response) => response.json());
    if (promise) {
      if (requestJSON && requestJSON.isLoader !== false) {
        this.countEndApi += 1;
        if (this.countStartApi === this.countEndApi) {
          // store.dispatch({ type: 'REQUEST', status: 'REQUEST_FULFILLED' });
        }
      }
    }
    return promise;
  }

  async sendFileUploadRequest(requestJSON: RequestApi) {
    const token = localStorageService.getItem("token")
    const data = new FormData();
    data.append("file", requestJSON.body.pickedFile);
    const promise = await fetch(requestJSON.url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
      body: data,
    }).then((response) => {
      return response.json();
    });
    return promise;
  }
}
export default FetchSendRequest;
