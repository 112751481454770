import { useEffect, useState } from "react";
import useFetch from "use-http";
import { GET_CAMPAIGN_URL } from "../../constants/urls";
import { Campaign, ICampaign } from "../../models/campaign.interface";

interface IReturnState {
  campaigns: Array<Campaign>;
  loading: boolean;
}
export const useCampaignState = (): IReturnState => {
  const { get, loading, response } = useFetch();
  const [campaigns, setCampaigns] = useState<Array<Campaign>>([]);

  const fetchCampaign = async () => {
    const campaignResponse: ICampaign =  await get(GET_CAMPAIGN_URL);

    if (response.ok && campaignResponse.status == 200) {
        setCampaigns(campaignResponse.data);
    } else {
    }
  };
  useEffect(() => {
    fetchCampaign()
  }
  , []);
  return { campaigns, loading };
};
