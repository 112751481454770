import { combineReducers, Reducer } from '@reduxjs/toolkit'
import authSlice from './slice/auth.slice';
import cardsSlice from './slice/cards.slice';
import userSlice from './slice/user.slice';



export const createRootReducer = (): Reducer<any> =>
  combineReducers({
    auth:authSlice,
    user:userSlice,
    cards:cardsSlice
  });

export type RootState = ReturnType<typeof createRootReducer>

export default createRootReducer