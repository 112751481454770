import { CheckCircleTwoTone, DeleteOutlined } from "@ant-design/icons";
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    message,
    Button,
    Avatar,
    Typography,
    Tooltip,
    Spin,
  } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
  import face2 from "../../assets/images/face-2.jpg";
import { deleteReportedCardRequest, getReportedCardsRequest, resolveReportedCardRequest, setCardFilters } from "../../store/slice/cards.slice";
  import { columns } from "./data/columns";
  
  const { Title } = Typography;
  function ReportedCards() {
    const {loading,filteredData,filter}  = useSelector((store:any) =>store.cards );
    const dispatch = useDispatch()

    const getReportedCards =()=>{
      dispatch(getReportedCardsRequest())
    }
    useEffect(()=>{
      getReportedCards()
    },[])
    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Reported Cards"
                extra={
                  <>
                    <Radio.Group onChange={(e)=>dispatch(setCardFilters(e.target.value))} defaultValue={filter}>
                      <Radio.Button value="all">All</Radio.Button>
                      <Radio.Button value="resolved">Resolved</Radio.Button>
                      <Radio.Button value="unresolved">UnResolved</Radio.Button>
                    </Radio.Group>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                  loading= {loading}
                    columns={columns}
                    dataSource={filteredData.map((item:any)=>{
                      return {
                        ...item,
                        name:( <>
                          <Avatar.Group>
                            <Avatar
                              className="shape-avatar"
                              shape="square"
                              size={40}
                              src={face2}
                            ></Avatar>
                            <div className="avatar-info">
                              <Title level={5}>{item.reportedCard.cardDetails.title}</Title>
                              <p>{item.email}</p>
                            </div>
                          </Avatar.Group>{" "}
                        </>),
                        status:(
                          <>
                          <Button type="primary" className="tag-primary">
                          {
                            item.resolved ? "YES" :"NO"
                          }
                          </Button>
                          </>
                        ),
                        actions:(
                         <>
                          {
                            item.loading ? <><Spin/></> :  <> 
                            {
                              !item.resolved ?  <Tooltip title="resolve card">
                              <CheckCircleTwoTone  onClick={()=>dispatch(resolveReportedCardRequest(item.reportId))} style={{fontSize:"15px",marginRight:"10px"}} />
                              </Tooltip>
                              :""
                            }
                            <DeleteOutlined onClick={()=>dispatch(deleteReportedCardRequest(item.reportId))} style={{color:"red",fontSize:"15px"}}/>
                            </>
                          }
                          </>                
                        )
                      }
                    })}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
  
  export default ReportedCards;
  