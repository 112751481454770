import { createSlice } from '@reduxjs/toolkit';
import { localStorageService } from '../../utils/localStorage/localStorage';
import { AuthState } from '../state-types/authState';
  
const initialState:AuthState = {
  loading:false,
  isLogedIn:localStorageService.getItem("token") ? true :false,
  authUser:localStorageService.getItem("authUser") ? localStorageService.getItem("authUser") :{},
  error:{}

};
  
const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest:(state,{})=>{
        state.loading=true
    },
    login: (state, { payload }) => {
    state.loading = false;
    state.isLogedIn = true;
    state.authUser=payload
    },
    loginFailed:(state,{payload}) =>{
      state.loading = false
      state.error = payload
    }
  },
});
  
export const { login,loginRequest,loginFailed } = AuthSlice.actions;
              
export default AuthSlice.reducer;