import {
  Card,
  Col,
  Row,
  Typography,
  Input,
  Form,
  InputNumber,
  Button,
  Divider,
} from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IPackageList, ISinglePackage } from "../../models/packages.interface";
import { usePackagesState } from "./packagesState";
import { List, Switch } from "antd";
import { PackageActions } from "../../interfaces/package.enum";

const { Title } = Typography;

function EditPackage() {
  const { loading, packages, updatePackageActions } = usePackagesState();
  const history = useHistory();
  const packagesFlag: Array<string> = [];

  //@ts-ignore
  const packageDetails: ISinglePackage = history.location.state.package;
  const [packageTitle, setPackageTitle] = useState<string>(
    packageDetails.packageTitle
  );
  const [packageDescription, setPackageDescription] = useState<string>(
    packageDetails.packageDescription
  );

  const getActionState = (action: PackageActions): boolean => {
    const isIncluded = packageDetails.actionsAllowed.includes(action);
    if (isIncluded) {
      packagesFlag.push(action);
    }
    return isIncluded;
  };

  const [packageList, setPackageList] = useState<Array<IPackageList>>([
    {
      title: "Create Brand",
      key: PackageActions.POST_BRAND,
      state: getActionState(PackageActions.POST_BRAND),
    },
    {
      title: "Create Campaign",
      key: PackageActions.POST_CAMPAIGN,
      state: getActionState(PackageActions.POST_CAMPAIGN),
    },
    {
      title: "Create Card",
      key: PackageActions.POST_CARD,
      state: getActionState(PackageActions.POST_CARD),
    },
    {
      title: "Link Widget",
      key: PackageActions.LINK_ACTION,
      state: getActionState(PackageActions.LINK_ACTION),
    },
    {
      title: "Contact Widget",
      key: PackageActions.CONTACT_ACTION,
      state: getActionState(PackageActions.CONTACT_ACTION),
    },
    {
      title: "Event/Calendar Widget",
      key: PackageActions.EVENT_ACTION,
      state: getActionState(PackageActions.EVENT_ACTION),
    },
    {
      title: "Information Widget",
      key: PackageActions.INFORMATION_ACTION,
      state: getActionState(PackageActions.INFORMATION_ACTION),
    },
    {
      title: "Location Widget",
      key: PackageActions.LOCATION_ACTION,
      state: getActionState(PackageActions.LOCATION_ACTION),
    },
    {
      title: "Media Widget",
      key: PackageActions.MEDIA_ACTION,
      state: getActionState(PackageActions.MEDIA_ACTION),
    },
    {
      title: "Dynamic QR Code",
      key: PackageActions.DYNAMIC_QR,
      state: getActionState(PackageActions.DYNAMIC_QR),
    },
    {
      title: "Computer Vision/Image Matching",
      key: PackageActions.COMPUTER_VISION,
      state: getActionState(PackageActions.COMPUTER_VISION),
    },
    {
      title: "Subscribe Widget",
      key: PackageActions.SUBSCRIBE_ACTION,
      state: getActionState(PackageActions.SUBSCRIBE_ACTION),
    },
    {
      title: "Share Widget",
      key: PackageActions.SHARE_ACTION,
      state: getActionState(PackageActions.SHARE_ACTION),
    },
    {
      title: "RSVP Widget",
      key: PackageActions.RSVP_ACTION,
      state: getActionState(PackageActions.RSVP_ACTION),
    },
  ]);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const updatePackageFlag = (v: boolean, flag: string) => {
    if (v) {
      if (packagesFlag.includes(flag)) {
      } else {
        packagesFlag.push(flag);
      }
    } else {
      if (packagesFlag.includes(flag)) {
        const index = packagesFlag.indexOf(flag);
        if (index > -1) {
          packagesFlag.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
  };

  const onFinish = (values: any) => {
    console.log(values);
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={`Package - ${packageDetails.packageTitle}`}
            >
              <div style={{ padding: 20 }}>
                <Title style={{ fontSize: 14, fontWeight: "normal" }}>
                  Package Name
                </Title>
                <Input
                  onChange={(v) => {
                    const value = v.target.value;
                    if (value == "") {
                      setPackageTitle(packageDetails.packageTitle);
                    } else {
                      setPackageTitle(value);
                    }
                  }}
                  defaultValue={packageDetails.packageTitle}
                />

                <Title
                  style={{ fontSize: 14, fontWeight: "normal", marginTop: 12 }}
                >
                  Package Description
                </Title>
                <Input.TextArea
                  rows={4}
                  onChange={(v) => {
                    const value = v.target.value;

                    if (value == "") {
                      setPackageDescription(packageDetails.packageDescription);
                    } else {
                      setPackageDescription(v.target.value);
                    }
                  }}
                  defaultValue={packageDetails.packageDescription}
                />
              </div>

              <Title style={{ padding: 20 }} level={4}>
                Actions
              </Title>

              <List
                bordered
                dataSource={packageList}
                renderItem={(item) => (
                  <>
                    <Row style={{ padding: 20 }}>
                      <Col span={12}>{item.title}</Col>
                      {/* @ts-ignore */}
                      <Col span={12} align="right">
                        <Switch
                          defaultChecked={item.state}
                          onChange={(v) => {
                            updatePackageFlag(v, item.key);
                          }}
                        />
                      </Col>
                    </Row>
                    <Divider />
                  </>
                )}
              />

              <div style={{ padding: 20 }}>
                <Button
                  type="primary"
                  block
                  loading={loading}
                  onClick={() => {
                    updatePackageActions(
                      packageDetails.packageId,
                      packageTitle,
                      packageDescription,
                      packagesFlag
                    );
                  }}
                >
                  Save
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default EditPackage;
