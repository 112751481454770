import React, { useEffect } from "react";
import { Avatar, Button, Card, Col, Row, Table, Typography } from "antd";
import AppHelpers from "../../utils/app/appHelper";
import { campaignData } from "./data/campaignData";
import { useCampaignState } from "../states/campaign-state";
import { format } from "date-fns";

const { Title } = Typography;
function CampaignListing() {
  const { loading, campaigns } = useCampaignState();
  useEffect(() => {
    console.log(campaigns);
  }, []);
  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Campaigns"
            >
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={campaignData}
                  dataSource={
                    campaigns &&
                    campaigns.map((item: any) => {
                      const createdAt = format(
                        new Date(item.createdAt),
                        "MMM-dd-yyyy HH:mm"
                      );
                      return {
                        key: item._id,
                        image: (
                          <>
                            <Avatar.Group>
                              <Avatar
                                className="shape-avatar"
                                shape="square"
                                size={100}
                                src={AppHelpers.getImageUrl(item.image)}
                              ></Avatar>
                            </Avatar.Group>{" "}
                          </>
                        ),
                        title: (
                          <>
                            <div>
                              <Title
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                }}
                                level={5}
                              >
                                {item.title}
                              </Title>
                            </div>
                          </>
                        ),
                        description: (
                          <>
                            <div>
                              <Title
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                  whiteSpace: "normal",
                                }}
                                level={5}
                              >
                                {item.description}
                              </Title>
                            </div>
                          </>
                        ),
                        creator: (
                          <>
                            <Title
                              level={5}
                              style={{ fontWeight: "normal", fontSize: "14px" }}
                            >
                              {item.ownerId}
                            </Title>
                          </>
                        ),
                        time: (
                          <>
                            <Title
                              style={{
                                fontWeight: "normal",
                                fontSize: "14px",
                                whiteSpace: "normal",
                              }}
                              level={5}
                            >
                              {createdAt}
                            </Title>
                          </>
                        ),
                        action: (
                          <>
                            <div>
                              <Button
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                }}
                                size="small"
                              >
                                ACTIVE
                              </Button>
                            </div>
                          </>
                        ),
                      };
                    })
                  }
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default CampaignListing;
