export enum PackageActions {
  "POST_BRAND" = "POST_BRAND",
  "POST_CAMPAIGN" = "POST_CAMPAIGN",
  "POST_CARD" = "POST_CARD",
  "LINK_ACTION" = "LINK_ACTION",
  "CONTACT_ACTION" = "CONTACT_ACTION",
  "EVENT_ACTION" = "EVENT_ACTION",
  "INFORMATION_ACTION" = "INFORMATION_ACTION",
  "LOCATION_ACTION" = "LOCATION_ACTION",
  "MEDIA_ACTION" = "MEDIA_ACTION",
  "DYNAMIC_QR" = "DYNAMIC_QR",
  "COMPUTER_VISION" = "COMPUTER_VISION",
  "SHARE_ACTION" = "SHARE_ACTION",
  "SUBSCRIBE_ACTION" = "SUBSCRIBE_ACTION",
  "RSVP_ACTION" = "RSVP_ACTION",
}
