import React from 'react'
import { Redirect, Route } from 'react-router-dom'

export default function ProtectedRoutes({isAuth:isAuth,component:Component,...rest}:any):JSX.Element {
  return (
    <Route {...rest} render={(props:any)=>{
        if(isAuth){
            return <Component/>
        }
        else{
            return <Redirect to="/sign-in"/>
        }
    }}/>
  )
}
