/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {
  Switch,
  Route,
  Redirect,
  BrowserRouter,
  useHistory,
} from "react-router-dom";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Main from "./components/layout/Main";
import SignIn from "./pages/SignIn";
import Home from "./pages/Home";
import Billing from "./pages/Billing";
import Profile from "./pages/Profile";
import UsersListing from "./pages/users/UsersListing";
import ReportedCards from "./pages/reportedCards";
import ProtectedRoutes from "./routes/protectedRoutes";
import { useSelector } from "react-redux";
import CampaignListing from "./pages/users/campaignListing";
import CardListing from "./pages/users/cardListing";
import BrandListing from "./pages/users/brandListing";
import PackagesListing from "./pages/packages/packagesListing";
import EditPackage from "./pages/packages/EditPackage";

function App() {
  const { isLogedIn } = useSelector((store: any) => store.auth);
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/sign-in" exact component={SignIn} />
          <Main>
            <ProtectedRoutes
              exact
              path="/dashboard"
              component={Home}
              isAuth={isLogedIn}
            />
            <ProtectedRoutes
              exact
              path="/users"
              component={UsersListing}
              isAuth={isLogedIn}
            />
            <ProtectedRoutes
              exact
              path="/reported-cards"
              component={ReportedCards}
              isAuth={isLogedIn}
            />
            <ProtectedRoutes
              exact
              path="/billing"
              component={Billing}
              isAuth={isLogedIn}
            />
            <ProtectedRoutes
              exact
              path="/profile"
              component={Profile}
              isAuth={isLogedIn}
            />
            <ProtectedRoutes
              exact
              path="/campaign"
              component={CampaignListing}
              isAuth={isLogedIn}
            />
            <ProtectedRoutes
              exact
              path="/card"
              component={CardListing}
              isAuth={isLogedIn}
            />
            <ProtectedRoutes
              exact
              path="/brand"
              component={BrandListing}
              isAuth={isLogedIn}
            />
            <ProtectedRoutes
              exact
              path="/packages"
              component={PackagesListing}
              isAuth={isLogedIn}
            />
            <ProtectedRoutes
              exact
              path="/edit-package"
              component={EditPackage}
              isAuth={isLogedIn}
            />
          </Main>
          <Redirect from="/" to="/sign-in" />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
