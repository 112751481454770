// table code start
export const cardData = [
  {
    title: "IMAGE",
    dataIndex: "image",
    key: "image",
    width: "12%",
  },
  {
    title: "PUBLISHER",
    dataIndex: "publisher",
    key: "publisher",
  },

  {
    title: "TITLE",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "DESCRIPTION",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "CREATED AT",
    dataIndex: "createdat",
    key: "createdat",
  },
  {
    title: "EXPIRED AT",
    dataIndex: "expire",
    key: "expire",
  },
  {
    title: "ACTION",
    dataIndex: "action",
    key: "action",
  },
 
];
