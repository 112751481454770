// table code start
export const brandData = [
  {
    title: "TITLE",
    dataIndex: "title",
    key: "title",
    width: "12%",
  },

  {
    title: "DESCRIPTION",
    key: "description",
    dataIndex: "description",
  },
  {
    title: "CREATED AT",
    key: "createdAt",
    dataIndex: "createdAt",
  },
  {
    title: "LOGO URL",
    key: "logoUrl",
    dataIndex: "logoUrl",
  },
];
