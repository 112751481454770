// table code start
export const packagesColumns = [
  {
    title: "Package Title",
    dataIndex: "packageTitle",
    key: "packageTitle",
    width: "32%",
  },
  {
    title: "Package Description",
    dataIndex: "packageDescription",
    key: "packageDescription",
    width: "45%",
  },
  {
    title: "ACTION",
    key: "action",
    dataIndex: "action",
  },
  {
    title: "CREATED AT",
    key: "createdAt",
    dataIndex: "createdAt",
  },
];
