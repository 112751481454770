import React, { useEffect } from "react";
import { Card, Col, Row, Table, Typography, Button } from "antd";
import { useBrandState } from "../states/brand-state";
import { format } from "date-fns";
import { usePackagesState } from "./packagesState";
import { packagesColumns } from "../users/data/packagData";
import { ISinglePackage } from "../../models/packages.interface";
import { useHistory } from "react-router-dom";

const { Title } = Typography;

function PackagesListing() {
  const { loading, packages } = usePackagesState();
  const history = useHistory();

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Packages"
            >
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={packagesColumns}
                  dataSource={
                    packages &&
                    packages.map((item: ISinglePackage) => {
                      const createdAt = format(
                        new Date(item.createdAt),
                        "MMM-dd-yyyy HH:mm"
                      );
                      return {
                        key: item._id,
                        packageTitle: (
                          <>
                            <div>
                              <Title
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                }}
                                level={5}
                              >
                                {item.packageTitle}
                              </Title>
                            </div>
                          </>
                        ),
                        packageDescription: (
                          <>
                            <div>
                              <Title
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                  whiteSpace: "normal",
                                }}
                                level={5}
                              >
                                {item.packageDescription}
                              </Title>
                            </div>
                          </>
                        ),
                        action: (
                          <>
                            <Button
                              type="primary"
                              danger
                              onClick={() =>
                                history.push("/edit-package", {
                                  package: item,
                                })
                              }
                            >
                              Edit
                            </Button>
                          </>
                        ),

                        createdAt: (
                          <>
                            <div>
                              <Title
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                }}
                                level={5}
                              >
                                {createdAt}
                              </Title>
                            </div>
                          </>
                        ),
                      };
                    })
                  }
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default PackagesListing;
